import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import "antd/dist/antd.css";
import "./App.css";
import "./assets/css/global.css";
import "./assets/css/filter.css";
import "./assets/css/index.css";

//Komponen 
import DigitalIdentification from "./container/digital-identification";

import './App.css';
function App() {
	return (
		<Router>
			<Switch>
				<Route path="/:id">
					<DigitalIdentification/>
				</Route>
				<Route>
					<h2>Oops wrong way.</h2>
				</Route>
			</Switch>
		</Router>
	);
}

export default App;
