import React, {useState, useEffect, Fragment} from "react";
import axios                from 'axios';
import { message }          from "antd";

import { useParams }        from "react-router-dom";

//Komponen
import IndexUnknown         from "./unknown";
import IndexVerified        from "./verified";
import IndexUnknownPotrait  from "./unknown_potrait";
import IndexVerifiedPotrait from "./verified_potrait";

import { useMediaQuery }    from 'react-responsive'

const DigitalIdentification = () => {

    const isPortrait    = useMediaQuery({ query: '(orientation: portrait)' });
    const isLandscape   = useMediaQuery({ query: '(orientation: landscape)' });

    let { id } = useParams();

    const [status, setStatus]      = useState();
    const [identity, setIdentity]  = useState([]);

    const getIdentification = () => {
        setStatus(null);
        let api_url = !process.env.NODE_ENV || process.env.NODE_ENV === 'production' ? window._env_.REACT_APP_API_GATEWAY : process.env.REACT_APP_API_GATEWAY;

        axios({
            method  : 'POST',
            url     : `${window.location.protocol}//${window.location.hostname}/decrypt?key=${id}`,
        }).then((res) => {
            if(res.status === 200){
                setIdentity(res.data.data);
                setStatus("verified");
            }else{
                message.success(res.status);
            }
        }).catch((err) => {
            // if("response" in err){
            //     if(err.response.status === 403){
            //         message.info('Link Expire.');
            //     }
            //     if(err.response.status === 500){
            //         message.info('Link Tidak Valid.');
            //     }
            // }
            console.log(err);
        });
    }

    useEffect(() => {
        getIdentification();
    }, []);

    return (
        <Fragment>
            {
                status == "verified" ?
                    isLandscape ?
                        <IndexVerified
                            name            = {identity.name}
                            sector          = {identity.sector}
                            position        = {identity.position}
                            workunit        = {identity.workunit}
                            identity_id     = {identity.identity_id}
                            photo           = {identity.photo}
                            encryptedText   = {`${window.location.protocol}//digital-identification.${window.location.hostname}/`+id}
                        /> 
                    :
                        <IndexVerifiedPotrait
                            name            = {identity.name}
                            sector          = {identity.sector}
                            position        = {identity.position}
                            workunit        = {identity.workunit}
                            identity_id     = {identity.identity_id}
                            photo           = {identity.photo}
                            encryptedText   = {`${window.location.protocol}//digital-identification.${window.location.hostname}/`+id}
                        />
                : 
                    isLandscape ?
                        <IndexUnknown
                            encryptedText   = {`${window.location.protocol}//digital-identification.${window.location.hostname}/`+id}
                        />
                    :   
                        <IndexUnknownPotrait
                            encryptedText   = {`${window.location.protocol}//digital-identification.${window.location.hostname}/`+id}
                        />
            }
        </Fragment>
    )

}

export default DigitalIdentification;
