import React from 'react';
import { Card, Row, Col, Image } from 'antd';
import QRCode from 'qrcode.react';

import profile   from '../../assets/image/150x150.png';
import qrcode    from '../../assets/image/qrcode.png';
import logo      from '../../assets/image/logo-kejaksaan.png';
import subtract  from '../../assets/image/subtract.png';
import subtract1 from '../../assets/image/subtract1.png';
import verified  from '../../assets/image/verified.png';
import subtractLarge from '../../assets/image/subtract-large.png';


const IndexVerifiedPotrait = (props) => {
    return (
        <div className="bg-cantainer">
            <div style={{ paddingTop: '10vh' }}>
                <Row>
                    <Col span={18} offset={3}>
                        <Card className="shadow-sm" bodyStyle={{ padding: '20px 40px 10px 40px', height: '35vh' }}>
                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                <Col className="gutter-row" span={13}>
                                    <Card className="emp-card shadow-sm mt-0" style={{ position: 'relative' }} bodyStyle={{ padding: '0px', paddingTop: '-5vh', height: '25vh' }}>
                                        <div className="p-3">
                                            <img src={logo} alt="logo" className="img-kejaksaan" style={{ width: '8vw', height: '8vw' }}/>
                                            <Card className="emp-card-profile text-center">
                                                <p className="m-0" style={{fontSize: '0.9vw'}}>KEJAKSAAN AGUNG RI</p>
                                                <p className="m-0 text-bold" style={{ fontSize: '1.1vw'}}>
                                                    {props.name}
                                                </p>
                                                <p style={{fontSize: '0.9vw'}}>NIP : {props.identity_id}</p>
                                                <br/>
                                            </Card>
                                            <div className="text-center mb-3">
                                                <Image width="11vw" height="11vw" fallback={props.photo} src={profile} alt="profile" className="image-profile" style={{ width: '100%' }}/>
                                            </div>
                                            <div className="text-center" style={{ marginTop: '-9vh' }}>
                                                <p className="m-0" style={{ color: '#000', fontSize: '1vw'}}>
                                                    {props.position}
                                                </p>
                                                <p className="text-bold" style={{ color: '#000', fontSize: '1vw' }}>
                                                    {props.sector}
                                                </p>
                                            </div>
                                        </div>
                                        <div style={{ backgroundColor: '#447e22', padding: '10px', borderRadius: '7px' }}></div>
                                    </Card>
                                    <div className="text-center">
                                        <QRCode
                                            value={props.encryptedText}
                                            // className="img-fluid img-qrcode"
                                            style={{ marginTop: '-3vh', backgroundColor: '#fff', borderRadius: '3px', height: '7vw', width: '7vw', position: 'relative' }}
                                        />
                                    </div>
                                    <div style={{ marginTop: '-180px' }}>
                                        <img src={subtract} alt="subtract" style={{ float: 'right', marginRight: '5rem', width: '15vw' }}/>
                                    </div>
                                </Col>
                                <Col className="gutter-row" span={11}>
                                    <div className="mt-4" style={{ fontSize: '20px'}}>
                                        <img src={subtractLarge} alt="subtractLarge" style={{ position: 'absolute', marginLeft: '50px', marginTop: '5vh', width: '70%', height: 'auto'}}/>
                                        <div style={{ position: 'absolute' }}>
                                            <div>
                                                <p className="m-0" style={{fontSize: '1.4vw'}}>Identitas terdaftar dalam</p>
                                                <p className="text-primary text-bold" style={{fontSize: '1.4vw'}}>
                                                    Sistem Informasi Adhyaksa Command Center
                                                </p>
                                            </div>
                                            <div className="mt-2">
                                                <p style={{fontSize: '1.4vw'}}>
                                                    Tercatat sebagai &nbsp;
                                                    <span className="text-primary text-bold">
                                                        Pegawai Resmi
                                                    </span><br/>
                                                    Kejaksaan Agung R.I
                                                </p>
                                            </div>
                                            <div className="mt-3">
                                                <p className="m-0" style={{fontSize: '1.4vw'}}>Jaksa Agung Muda</p>
                                                <p className="text-primary text-bold" style={{fontSize: '1.4vw'}}>
                                                    {props.sector}
                                                </p>
                                            </div>
                                            <div className="mt-3">
                                                <p className="m-0" style={{fontSize: '1.4vw'}}>Pada satuan kerja</p>
                                                <p className="text-primary text-bold" style={{fontSize: '1.4vw'}}>
                                                    {props.workunit}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                    <Col span={18} offset={3}>
                        <Card style={{marginTop: '3vh'}} className="shadow-sm text-center" bodyStyle={{ height: '35vh' }}>
                            <div className="text-center" style={{ marginTop: '50px', marginBottom: '20px' }}>
                                <img src={verified} alt="verified" style={{ width: '20vw' }}/>
                            </div>
                            <h1 style={{ fontSize: '5vw', color: '#447e22', fontWeight: 'bold' }}>Verified</h1>
                            <div style={{ marginTop: '-220px', marginLeft: '-10px' }}>
                                <img src={subtract1} alt="subtract1" style={{ float: 'left', width: '25vw' }}/>
                            </div>
                        </Card>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default IndexVerifiedPotrait;
