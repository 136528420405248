import React from 'react';
import { Card, Row, Col, Image } from 'antd';
import QRCode from 'qrcode.react';

import profile   from '../../assets/image/150x150.png';
import qrcode    from '../../assets/image/qrcode.png';
import logo      from '../../assets/image/logo-kejaksaan.png';
import subtract  from '../../assets/image/subtract.png';
import subtract1 from '../../assets/image/subtract1.png';
import verified  from '../../assets/image/verified.png';
import subtractLarge from '../../assets/image/subtract-large.png';


const IndexVerified = (props) => {
    return (
        <div className="bg-cantainer">
            <div className="containers">
            <Row gutter={32} >
                <Col span={13} offset={3}>
                    <Card className="shadow-sm" bodyStyle={{ padding: '40px', height: '90vh' }}>
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                            <Col className="gutter-row" span={12}>
                                <Card className="emp-card shadow-sm mt-0" style={{ position: 'relative' }} bodyStyle={{ padding: '0px', height: '60vh' }}>
                                    <div className="p-3">
                                        <img src={logo} alt="logo" className="img-kejaksaan" style={{ width: '6vw', height: '6vw' }}/>
                                        <Card className="emp-card-profile text-center">
                                            <p className="m-0" style={{fontSize: '0.9vw'}}>KEJAKSAAN AGUNG RI</p>
                                            <p className="m-0 text-bold" style={{ fontSize: '1.1vw'}}>
                                                {props.name}
                                            </p>
                                            <p style={{fontSize: '0.9vw'}}>NIP : {props.identity_id}</p>
                                            <br/>
                                        </Card>
                                        <div className="text-center mb-3">
                                            <Image width="6vw" height="6vw" fallback={props.photo} src={profile} alt="profile" className="image-profile" style={{ width: '100%' }}/>
                                        </div>
                                        <div className="text-center mt-2" style={{ marginBottom: '30px' }}>
                                            <p className="m-0" style={{ color: '#000', fontSize: '0.8vw'}}>
                                                {props.position}
                                            </p>
                                            <p className="text-bold" style={{ color: '#000', fontSize: '0.8vw' }}>
                                                {props.sector}
                                            </p>
                                        </div>
                                    </div>
                                    <div style={{ backgroundColor: '#447e22', padding: '10px', borderRadius: '7px' }}></div>
                                </Card>
                                <div className="text-center">
                                    <QRCode
                                        value={props.encryptedText}
                                        className="img-fluid img-qrcode"
                                        style={{ marginTop: '-60px', backgroundColor: '#fff', borderRadius: '3px', width: '6vw', height: '6vw' }}
                                    />
                                    {/* <Image fallback={qrcode} width={130} src={qrcode} alt="qrcode" style={{ marginTop: '-50px' }}></Image> */}
                                </div>
                                <div style={{ marginTop: '-190px' }}>
                                    <img src={subtract} alt="subtract" style={{ float: 'right', marginRight: '5rem', width: '10vw'}}/>
                                </div>
                            </Col>
                            <Col className="gutter-row" span={12}>
                                <div className="mt-4" style={{ fontSize: '20px'}}>
                                    <img src={subtractLarge} alt="subtractLarge" style={{ position: 'absolute', marginLeft: '50px', marginTop: '5vh', width: '70%', height: 'auto'}}/>
                                    <div style={{ position: 'absolute' }}>
                                        <div>
                                            <p className="m-0" style={{fontSize: '1.1vw'}}>Identitas terdaftar dalam</p>
                                            <p className="text-primary text-bold" style={{fontSize: '1.1vw'}}>
                                                Sistem Informasi Adhyaksa Command Center
                                            </p>
                                        </div>
                                        <div className="mt-4">
                                            <p style={{fontSize: '1.1vw'}}>
                                                Tercatat sebagai &nbsp;
                                                <span className="text-primary text-bold">
                                                    Pegawai Resmi
                                                </span><br/>
                                                Kejaksaan Agung R.I
                                            </p>
                                        </div>
                                        <div className="mt-4">
                                            <p className="m-0" style={{fontSize: '1.1vw'}}>Jaksa Agung Muda</p>
                                            <p className="text-primary text-bold" style={{fontSize: '1.1vw'}}>
                                                {props.sector}
                                            </p>
                                        </div>
                                        <div className="mt-4 mb-3">
                                            <p className="m-0" style={{fontSize: '1.1vw'}}>Pada satuan kerja</p>
                                            <p className="text-primary text-bold" style={{fontSize: '1.1vw'}}>
                                                {props.workunit}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col span={5}>
                    <Card className="shadow-sm text-center" bodyStyle={{ height: '90vh' }}>
                        <div className="text-center" style={{ marginTop: '90px', marginBottom: '40px' }}>
                            <img src={verified} alt="verified" style={{ width: '80%', height: 'auto' }}/>
                        </div>
                        <h1 style={{ fontSize: '3vw', color: '#447e22', fontWeight: 'bold' }}>Verified</h1>
                        <div style={{ marginTop: '-240px', marginLeft: '-10px' }}>
                            <img src={subtract1} alt="subtract1" style={{ float: 'left', width: '60%', height: 'auto' }}/>
                        </div>
                    </Card>
                </Col>
            </Row>
        </div>
        </div>
    );
};

export default IndexVerified;
