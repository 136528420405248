import React                from 'react';
import { Card, Row, Col, Image }   from 'antd';
import QRCode               from 'qrcode.react'; 


import close                from '../../assets/image/close.png';
import subtract2            from '../../assets/image/subtract2.png';
import unknown              from '../../assets/image/unknown.png';


const IndexUnknown = (props) => {

    return (
        <div className="bg-cantainer">
            <div className="containers">
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col span={13} offset={3}>
                    <Card className="shadow-sm gradient-red" bodyStyle={{ padding: '40px', height: '90vh' }}>
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                            <Col className="gutter-row" span={12}>
                                <Card className="emp-card shadow-sm mt-0" style={{ position: 'relative' }} bodyStyle={{ padding: '0px', height: '60vh' }}>
                                    <div className="p-3">
                                        <div className="img-kejaksaan"></div>
                                        <Card className="emp-card-profile-red" style={{ height: '25vh'}}></Card>
                                        <div className="text-center">
                                            <Image 
                                                fallback={close} 
                                                src={close} 
                                                alt="close" 
                                                className="img-profile-close"
                                                style = {{ width:'5vw', height:'5vw' }}
                                            />
                                        </div>
                                        <div className="text-center mt-2" style={{ marginBottom: '140px' }}></div>
                                    </div>
                                    <div style={{ backgroundColor: '#dc2c2c', padding: '10px', borderRadius: '7px', marginTop: '-26vh' }}></div>
                                </Card>
                                
                                <div className="text-center">
                                    <QRCode
                                        value={props.encryptedText}
                                        style={{ marginTop: '-70px', backgroundColor: '#fff', borderRadius: '3px', height: '8vw', width: '8vw', position: 'relative' }}
                                    />
                                    {/* <Image fallback={qrcode} width={130} src={qrcode} alt="qrcode" style={{ marginTop: '-50px' }}></Image> */}
                                </div>
                            </Col>
                            <Col className="gutter-row" span={12}>
                                <div className="mt-4 text-center containers" style={{ fontSize: '2.5vw' }}>
                                    <p className="m-0">Identitas </p>
                                    <p>Tidak Terdaftar</p>
                                </div>
                            </Col>
                        </Row>
                    </Card>
                </Col>

                <Col span={5}>
                    <Card className="shadow-sm text-center gradient-red" bodyStyle={{ height: '90vh' }}>
                        <div className="text-center" style={{ marginTop: '90px', marginBottom: '40px' }}>
                            <img src={unknown} alt="unknown" style={{ width: '13vw' }}/>
                        </div>
                        <h1 style={{ fontSize: '2vw', color: '#dc2c2c', fontWeight: 'bold' }}>Unknown</h1>
                        <div style={{ marginTop: '-240px', marginLeft: '-10px' }}>
                            <img src={subtract2} alt="subtract2" style={{ float: 'left', width: '10vw' }}/>
                        </div>
                    </Card>
                </Col>
            </Row>
        </div>
        </div>
    );
};

export default IndexUnknown;