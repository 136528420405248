import React                from 'react';
import { Card, Row, Col }   from 'antd';
import QRCode               from 'qrcode.react'; 


import close                from '../../assets/image/close.png';
import subtract2            from '../../assets/image/subtract2.png';
import unknown              from '../../assets/image/unknown.png';


const IndexUnknownPotrait = (props) => {

    return (
        <div className="bg-cantainer">
            <div style={{ paddingTop: '10vh' }}>
                <Row>
                    <Col span={18} offset={3}>
                        <Card className="shadow-sm gradient-red" bodyStyle={{ padding: '40px', height: '35vh' }}>
                            <Row gutter={24}>
                                <Col className="gutter-row" span={13}>
                                    <Card className="emp-card shadow-sm mt-0" style={{ position: 'relative' }} bodyStyle={{ padding: '0px', height: '20vh' }}>
                                        <div className="p-3">
                                            <div className="img-kejaksaan" style={{ width: '8vw', height: '8vw'}}></div>
                                            <Card className="emp-card-profile-red" style={{height: '10vh'}}></Card>

                                            <div className="text-center mb-3">
                                                <img 
                                                    src         = {close} 
                                                    alt         = "close" 
                                                    style       = {{ width:'6vw', height:'6vw' }}
                                                    fallback    = {close} 
                                                    className   = "img-profile-close"
                                                />
                                            </div>

                                            <div className="text-center mt-2" style={{ marginBottom: '140px' }}></div>
                                        </div>

                                        {/* <div style={{ backgroundColor: '#dc2c2c', padding: '10px', borderRadius: '7px' }}/> */}
                                    </Card>
                                    
                                    <div className="text-center">
                                        <QRCode
                                            value={props.encryptedText}
                                            style={{ marginTop: '-70px', backgroundColor: '#fff', borderRadius: '3px', height: '8vw', width: '8vw', position: 'relative' }}
                                        />
                                        {/* <Image fallback={qrcode} width={130} src={qrcode} alt="qrcode" style={{ marginTop: '-50px' }}></Image> */}
                                    </div>
                                </Col>
                                <Col 
                                    span        = {11}
                                    className   = "gutter-row" 
                                    style       = {{ paddingTop: '5vh', paddingLeft:'2vh' }}
                                >
                                    <div className="mt-4 text-center" style={{fontSize: '4vw'}}>
                                        <p className="m-0">Identitas </p>
                                        <p>Tidak Terdaftar</p>
                                    </div>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                    <Col span={18} offset={3}>
                        <Card style={{marginTop: '3vh'}} className="shadow-sm text-center gradient-red" bodyStyle={{ height: '35vh' }}>
                            <div className="text-center" style={{ marginTop: '40px', marginBottom: '40px' }}>
                                <img src={unknown} alt="unknown" style={{ width: '20vw' }}/>
                            </div>
                            <h1 style={{ fontSize: '6vw', color: '#dc2c2c', fontWeight: 'bold' }}>Unknown</h1>
                            <div style={{ marginTop: '-240px', marginLeft: '-10px' }}>
                                <img src={subtract2} alt="subtract2" style={{ float: 'left', width: '30vw' }}/>
                            </div>
                        </Card>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default IndexUnknownPotrait;